import { useModalStore } from '~~/src/store/modal'
import { usePropertyStore } from '~~/src/store/property'
import { SimpleWidget, usePropertySidebar } from '~~/src/store/propertySidebar'
import type { ListingStatus } from '~~/src/types/Properties'

const useOptionsFactory = () => {
    const propertyStore = usePropertyStore()
    const propertySidebarStore = usePropertySidebar()
    const modalStore = useModalStore()

    const createOption = (label: string, newListingStatus: ListingStatus, newPropertyModal: typeof modalStore.propertyModal, icon: string) => {
        return {
            label,
            onclick: () => {
                propertyStore.propertyToChange.listingInformation = { ...propertyStore.propertyToChange.listingInformation!, listingStatus: newListingStatus }
                modalStore.propertyModal = newPropertyModal
                propertySidebarStore.isSidebarShow = false
            },
            icon
        }
    }

    const createCancelListingWidgetOption = () => createOption('Cancel Listing', 'canceled', { type: 'cancelListing' }, 'heroicons:minus-circle-20-solid')

    const createHoldListingWidgetOption = () => createOption('Place on \'Hold\'', 'hold', { type: 'holdListing' }, 'heroicons:hand-raised-solid')

    const createSubmitAContractOption = () => createOption('Submit Contract', 'pending', { type: 'statusChange', metadata: { status: 'pending' } }, 'heroicons:clipboard-document-check-solid')

    const createReactivateMyListingOption = () => createOption('Reactivate Listing', 'active', { type: 'statusChange', metadata: { status: 'active', withUpload: false } }, 'heroicons:arrow-path-rounded-square-solid')

    const createCloseMyListingOption = () => createOption('Close Listing', 'closed', { type: 'statusChange', metadata: { status: 'closed' } }, 'heroicons:trash-solid')

    return {
        createCancelListingWidgetOption,
        createHoldListingWidgetOption,
        createSubmitAContractOption,
        createReactivateMyListingOption,
        createCloseMyListingOption
    }
}

const createOptions = (status: ListingStatus) => {
    const { createCancelListingWidgetOption, createCloseMyListingOption, createHoldListingWidgetOption, createReactivateMyListingOption, createSubmitAContractOption } = useOptionsFactory()
    let options: { label: string, onclick?: () => void, isLink?: boolean, to?: string}[] = []

    switch (status) {
    case 'sent':
        options = [createCancelListingWidgetOption()]
        break
    case 'processing':
        options = [createCancelListingWidgetOption()]
        break
    case 'active':
        options = [createSubmitAContractOption(), createHoldListingWidgetOption(), createCancelListingWidgetOption()]
        break
    case 'pending':
        options = [createCloseMyListingOption(), createReactivateMyListingOption()]
        break
    case 'hold':
        options = [createCancelListingWidgetOption()]
    }
    return options
}

export const createStatusManagementWidget = (status: ListingStatus): SimpleWidget => {
    return {
        label: 'Status Management',
        links: createOptions(status),
        icon: 'heroicons:exclamation-circle-20-solid'
    }
}
