import { useModalStore } from '~~/src/store/modal'
import type { SimpleWidget } from '~~/src/store/propertySidebar'

export const createBrokerManagementWidget = (): SimpleWidget => {
    const modalStore = useModalStore()
    return {
        label: 'Broker Tools',
        links: [
            {
                label: 'Assign MLS Number',
                onclick: () => {
                    modalStore.propertyModal = { type: 'assignMls' }
                },
                icon: 'heroicons:document-magnifying-glass-solid'
            },
            {
                label: 'Listings',
                isLink: true,
                to: '/dashboard/listings',
                icon: 'heroicons:building-storefront-solid'
            },
            {
                label: 'Tasks',
                isLink: true,
                to: '/dashboard/tasks',
                icon: 'heroicons:check-badge-20-solid'
            }
        ],
        icon: 'heroicons:cog-solid'
    }
}
